import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I absolutely love what I do as a therapist. I am collaborative, working with your strengths and helping you access your own skills and resources to overcome your challenges. I believe in empowering you to take action and I love to create a safe environment to explore difficult emotions, beliefs, and behaviors. I believe in you. I am a person-centered therapist. I feel strongly about the inherent capacity of humans to heal and grow.
        </p>
        <p>
        I work with range of mental health and relationship challenges. But I focus specifically on helping individuals and couples struggling with anxiety, depression, faith transitions or faith crisis, women's issues, attachment trauma and challenges with self-worth or identity.
        </p>
        <p>
        I received my master’s degree in Marriage and Family Therapy from George Fox University in Portland, Oregon. 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
