import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Hi! Do you feel like something is holding you back? Is your mental health debilitating? If you're struggling with anxiety, depression, OCD, past trauma, low self-esteem, relationship problems, a faith crisis, or just want someone to vent to, you've come to the right place. I work with anyone who wants relief from emotional, social, spiritual, academic, and/or family burdens.
        </p>
        <p>
        I feel truly blessed to do the work I do. I love getting to know, listening, supporting, teaching, empowering and seeing adults, teens and kids grow and heal. I incorporate proven and effective techniques such as EMDR, CBT, ERP and DBT to help you manage, address, and overcome your problems.
        </p>
        <p>
        My goal is to help you become better at what you’re already doing well, improve what’s not working and get to the root of problems so you can find lasting change. Let's break the cycle that's keeping you stuck and gain freedom to live your ideal life. 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
