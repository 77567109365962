import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        We can make peace with your emotions. I see them as the roadmap to change. Your body knows what you need and will make you aware of those needs if we can help you attune yourself to them. Skills and strategies are a good way to keep yourself functioning, but sometimes people still find themselves returning to the same patterns when they use skills alone. My goal is to join with clients in their experiences to address personal, relational, and emotional patterns to find different ways of being. This includes incorporating culture, beliefs, family systems, personal strengths and positive ways of functioning to find lasting change.
        </p>
        <p>
        My clientele include first responders, couples, families, older individuals, and adolescent clients. Working with client's with various needs such as trauma, health/chronic pain, TBI, Autistic Spectrum Disorder (ASD), eating disorders, depression/anxiety, behavioral and conduct issues, and relationship conflicts has given me a broad range of experience.
        </p>
        <p>
        Joining with clients in their process of change is a tremendous privilege for me. I have been fortunate to see many clients experience the change they hope to find. Following my academic experiences in a masters and PhD program, I've seen therapy change many lives and promote corrective experiences for individuals, couples, and families.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
