import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        My name is Becky King and am a Licensed Clinical Social Worker who has worked in the field of substance use and mental health over the past 30 years. During this time, I have had the opportunity to work in State Government, the public and private sector helping make changes in systems and individuals’ lives.
        </p>
        <p>
        I have worked with worked with children, youth and adults over the years with various life concerns, including substance use, mental health, trauma, family relationship issues, criminal justice involvement and several other areas. My areas of specialty include substance use and mental health disorders, trauma, anxiety, depression, stress management, grief and loss.
        </p>
        <p>
        My primarily modalities for counseling include a person-centered approach, strength-based, solution focused, Mindfulness, holistic health and wellness, and the use of gender responsive, culturally sensitive methods in a Trauma-Informed environment. The Trauma-Informed Approach is not only for individuals that have experience trauma, but for everyone. It incorporates basic principles that help individuals feel safe and comfortable enough to work with through difficult issues and feel empowered to make positive changes in their lives. Below are the five key principles of a Trauma-Informed Approach:
          <br/>1. Safety
          <br/>2. Trustworthiness
          <br/>3. Choice
          <br/>4. Collaboration
          <br/>5. Empowerment
        </p>
        <p>
        I believe that everyone has the right to a happy, fulfilling life. Sometimes life challenges can make it difficult to see the light at the end of the tunnel. However, there is always room for hope, healing and recovery.
        </p>
        <p>
        Life is journey that can take us to places we never knew existed and there are caring individuals waiting to help you on this journey.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
